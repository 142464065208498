<template>
    <div class="timers" :class="[$mq]">
        <div class="top-container">
            <div v-if="Object.values(categories).length >= 1" class="categories" id="categories">
                <div>
                    <button v-if="!categorySelectedUnique" class="hamburger-menu" :class="{ active: displayView }" @click="toggleMenu"></button>
                    <div class="category-selected" v-if="categorySelectedUnique && isMobile">
                        <div
                            class="return-button"
                            @click="
                                displayView = true
                                categorySelectedUnique = null
                                categorySelected = '#&'
                            "
                        ></div>

                        <div
                            class="name-category"
                            :style="{
                                backgroundColor: categorySelectedUnique.color ? categorySelectedUnique.color : '#374673'
                            }"
                        >
                            <div
                                class="color"
                                v-if="categorySelectedUnique"
                                :style="{
                                    backgroundColor: categorySelectedUnique.color ? categorySelectedUnique.color : '#374673'
                                }"
                            ></div>
                            <div class="name">
                                {{ categorySelectedUnique.name ? categorySelectedUnique.name : $t('label.all') }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="category-options hide-scrollbar" ref="category_options" :class="{ 'mobile-hidden': !displayView, 'menu-active': displayView, 'category-hide': categorySelectedUnique }">
                    <span
                        v-if="Object.values(categories).length != 0"
                        class="category"
                        ref="state_all"
                        :class="{ selected: categorySelected == false }"
                        @click="
                            categorySelected = false
                            saveSelectCategory({ color: '#374673', name: $t('label.all') })
                        "
                    >
                        <span class="corner-dot" :style="{ backgroundColor: '#374673' }"></span>
                        <span class="category-text">
                            {{ $t('label.all') }}
                        </span>
                    </span>

                    <span
                        class="category"
                        v-for="(category, index) in categories"
                        :class="{ selected: category.id == categorySelected }"
                        @click="
                            categorySelected = categorySelected == category.id ? false : category.id
                            saveSelectCategory(category)
                        "
                        ref="categories"
                        :key="index"
                    >
                        <span class="corner-dot" :style="{ backgroundColor: category.color }"></span>

                        <span class="category-text">
                            {{ category.name }}
                        </span>
                    </span>
                </div>

                <AppccTopDetail v-if="!isMobile" class="appcc-top-detail" @orderPopup="showOrderPopup" :hasMoreOptions="false" :hasOrder="true" :showBackButton="false" :hasReopen="false" :hasDelete="false" />
            </div>
            <AppccTopDetail v-if="isMobile" class="appcc-top-detail" @orderPopup="showOrderPopup" :hasMoreOptions="false" :hasOrder="true" :showBackButton="false" :hasReopen="false" :hasDelete="false" />
        </div>
        <div class="container hide-scrollbar right right">
            <div class="timers-container">
                <SingleTimer v-for="timer in filteredTimers" :key="timer.id" :timerId="timer.id" @updateTimer="handleUpdateTimer" :color-dot="categories[timer.category_id]" :show-all="showAll" />
            </div>

            <div class="empty-page" v-if="filteredTimers.length > 0 && verifyShowsTimers">
                <EmptyPage :title="$t('empty_pages.generic_title')" :description="$t('empty_pages.timers_not_view')"></EmptyPage>
            </div>

            <div v-if="filteredTimers.length > 0" id="show-hide-timers" @click="showTimer ? hideTimers() : showTimers()">
                <div class="content-text-icon">
                    <span class="text">{{ showTimer ? $t('timers.view_minors') : $t('timers.view_all') }}</span>
                    <span v-if="showTimer" class="up-arrow"> </span>
                    <span v-else class="down-arrow"> </span>
                </div>
            </div>
        </div>

        <div class="popup-container blocking popup-small" v-if="isOrderPopupOpen">
            <div class="popup">
                <div class="list" :class="{ portrait: isApp || ['portrait'].includes($mq) }">
                    <div class="items-list" :class="{ 'mobile-landscape': isApp || ['portrait'].includes($mq) }">
                        <div class="item-list" v-for="option in sortingOptions" @click="changeOrder(option.value)" :class="[{ selected: selectedSortingOption === option.value }, { 'non-scroll': sortingOptions.length < 6 }]">
                            <span class="selector-color" :style="{ background: selectedSortingOption === option.value ? '#000' : 'transparent' }" :class="[{ 'no-color': selectedSortingOption !== option.value }]"></span>
                            <span class="selector-item">{{ option.displayText }}</span>
                        </div>
                    </div>
                    <Button bType="cancel" customClass="selector-popup" :bCallback="closePopup" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SingleTimer from '../components/domain/timers/SingleTimer.vue'
import EmptyPage from '../components/layout/EmptyPage.vue'
import AppccTopDetail from '../components/layout/AppccTopDetail.vue'
import Button from '../components/domain/ui/Button.vue'
import { SORT } from '@/constants'
import PortraitTopMenu from '@/components/domain/label/portraitTopMenu.vue'

export default {
    name: 'Timers',
    components: { SingleTimer, AppccTopDetail, Button, EmptyPage, PortraitTopMenu },
    data() {
        return {
            isMobile: false,
            displayView: true,
            categorySelectedUnique: [{ color: '#374673', name: this.$t('label.all') }],
            optionsCategories: [],
            categories: [],
            isOrderPopupOpen: false,
            selectedSortingOption: null,
            categorySelected: false,
            verifyShowsTimers: false,
            sortingOptions: [
                {
                    displayText: this.$t('order.ascending'),
                    value: SORT.ASCENDING
                },
                {
                    displayText: this.$t('order.descending'),
                    value: SORT.DESCENDING
                },
                {
                    displayText: this.$t('order.no_order'),
                    value: SORT.NO_ORDER
                }
            ],
            showTimer: false,
            showAll: false
        }
    },
    computed: {
        timers() {
            const self = this

            const timers = this.$store.getters['timer/getTimers']

            if (timers) {
                const uniqueCategoryIds = [...new Set(timers.map((item) => item.category_id))]

                this.categories = uniqueCategoryIds.reduce((result, id) => {
                    if (this.optionsCategories[id]) {
                        result[id] = this.optionsCategories[id]
                    }
                    return result
                }, {})

                const sortedCategories = Object.entries(this.categories).sort(([, a], [, b]) => a.order - b.order)
                const orderedCategories = Object.fromEntries(sortedCategories)
                this.categories = orderedCategories
            }

            if (!timers) {
                return
            }

            let sortedTimers

            const getComparableValue = (timer) => {
                return timer.isAvailable ? timer.currentPendingAlarm : timer.nextAlarm
            }

            if (self.selectedSortingOption == SORT.ASCENDING) {
                sortedTimers = timers.sort((a, b) => {
                    const valueA = getComparableValue(a)
                    const valueB = getComparableValue(b)
                    return valueA - valueB
                })
            } else if (self.selectedSortingOption == SORT.DESCENDING) {
                sortedTimers = timers.sort((a, b) => {
                    const valueA = getComparableValue(a)
                    const valueB = getComparableValue(b)
                    return valueB - valueA
                })
            } else {
                // no order = ordenar por timer.order
                sortedTimers = timers.sort((a, b) => {
                    if (a.order < b.order) return -1
                    if (a.order > b.order) return 1
                    return 0
                })
            }

            return sortedTimers
        },

        isApp() {
            return this.$store.getters.getIsApp
        },
        filteredTimers() {
            const self = this
            if (!this.categorySelected) {
                const counTimer = this.timers ? this.timers.filter((timer) => timer.hideTimer === false).length : 0
                self.verifyCountTimers(counTimer)
                return this.timers
            }
            if (this.timers) {
                const counTimerCategory = this.timers ? this.timers.filter((timer) => timer.category_id === this.categorySelected && timer.hideTimer === false).length : 0
                self.verifyCountTimers(counTimerCategory)
                return this.timers.filter((timer) => timer.category_id === this.categorySelected)
            }
        }
    },
    watch: {
        isMobile(beforeMobile, afterMobile) {
            if (!afterMobile) {
                this.displayView = true
            }
        }
    },
    methods: {
        loadTimers() {
            const self = this

            self.$overlay.loading()

            self.$store.dispatch('timer/loadTimers').then((response) => {
                self.$store.dispatch('timer/getLastTimesDone').then(() => {
                    setTimeout(() => {
                        self.$overlay.hide()
                    }, 100)
                })
            })
            self.verifyCountTimers()
        },
        loadCategories() {
            const self = this
            self.$store.dispatch('timer/loadCategories', {}).then(async () => {
                self.optionsCategories = await this.$store.getters['timer/getCategories']
            })
        },
        showOrderPopup() {
            this.isOrderPopupOpen = true
            this.$store.commit('setIsOrderPopupOpen', true)
        },
        changeOrder(option) {
            this.selectedSortingOption = option
            localStorage.setItem('savedSortingOption', this.selectedSortingOption)

            this.isOrderPopupOpen = false
        },
        closePopup() {
            this.isOrderPopupOpen = false
            this.$store.commit('setIsOrderPopupOpen', false)
        },
        setSelectedSortingOption() {
            const savedSortingOption = localStorage.getItem('savedSortingOption')

            if (savedSortingOption) {
                this.selectedSortingOption = parseInt(savedSortingOption)
            } else {
                this.selectedSortingOption = SORT.ASCENDING
            }
        },
        toggleMenu() {
            this.displayView = !this.displayView
        },
        saveSelectCategory(category) {
            this.categorySelectedUnique = category

            this.categorySelected = category.id !== undefined ? category.id : false
        },
        checkDeviceType() {
            this.isMobile = window.innerWidth <= 768
            return this.isMobile
        },
        showTimers() {
            const self = this
            self.$store.dispatch('timer/resetAllHideTimers')
            this.showTimer = true
            this.showAll = true
        },
        hideTimers() {
            this.showTimer = false
            const self = this
            self.$store.dispatch('timer/backAllHideTimers')
        },
        handleUpdateTimer() {
            // const elements = document.querySelectorAll('.maximum-six-hours-block')
            // if (elements.length > 0) {
            //     this.showTimers()
            // } else {
            //     this.hideTimers()
            // }
            // this.$nextTick(() => {
            //     console.log('Timers actualizados:', this.showTimer ? 'Mostrando timers' : 'Ocultando timers')
            // })
        },
        verifyCountTimers(countTimers) {
            const self = this
            if (countTimers === 0) {
                self.verifyShowsTimers = true
            } else {
                self.verifyShowsTimers = false
            }
        }
    },
    watch: {},
    created() {
        const self = this
        self.loadCategories()
        self.loadTimers()
        self.setSelectedSortingOption()
        this.checkDeviceType()
        window.addEventListener('resize', this.checkDeviceType)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkDeviceType) // Limpia el listener al destruir el componente
    },
    mounted() {}
}
</script>

<style lang="scss" scoped>
* {
    font-family: $text !important;
}

@media (max-width: 768px) {
    * {
        font-family: $text-bold !important;
    }
}

.empty-page {
    margin-top: 5rem;
    margin-bottom: 2rem;
}

#show-hide-timers {
    margin-top: 0.5rem;
    margin-bottom: 16px;
    width: 100%;
    text-align: right;
    cursor: pointer;

    .content-text-icon {
        display: inline-flex;
        align-items: flex-end;
        border-bottom: 2px solid #374673;
        padding-bottom: 2px; // Pequeño padding para separar el contenido de la línea
    }

    .text {
        color: #374673;
        font-weight: 700;
        font-size: 18px;
        white-space: nowrap;
        line-height: 1;
        font-family: $text;
    }

    .up-arrow,
    .down-arrow {
        display: inline-block;
        width: 15px;
        height: 24px; // Ajustado para coincidir con la altura del texto
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 8px;
    }

    .up-arrow {
        background-image: url('/img/up-arrow-374673.svg');
    }

    .down-arrow {
        background-image: url('/img/down-arrow-374673.svg');
    }
}

.timers {
    box-sizing: border-box;
    height: 100%;
    position: relative;

    .top-container {
        position: static;
        width: 100%;
        padding: 4px;
        padding-bottom: 0;
    }

    .container {
        overflow-y: auto;
        height: calc(100% - 50px);
        padding: 8px;

        .timers-container {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;
            max-width: 100%;

            // @media (min-width: 1025px) {
            //     display: grid;
            //     grid-template-columns: 1fr 1fr;
            // }

            @media (min-width: 696px) {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }

            @media (min-width: 1200px) {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }

    .popup-container {
        position: fixed;
    }

    .popup-container .items-list {
        padding-right: 0px !important;
        margin: 0px !important;
    }

    .popup-container.popup-small .popup {
        max-width: 350px;
    }

    .popup-container.blocking {
        z-index: 44;
    }

    .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        .selector-cancel-btn {
            width: 100%;
            margin: 0px;
        }
        .item-list {
            padding: 8px;
            display: flex;
            align-items: center;
            gap: 12px;
            cursor: pointer;
            width: 98%;
            margin: 8px 0;
            border: 1px solid $color-neutral-300;
            @include border-radius(4px);
            transition: all 0.3s ease-in-out;

            &.non-scroll {
                width: 100%;
            }

            &:hover {
                background-color: $color-selector;
            }

            &.selected {
                background-color: $color-neutral-200;
            }

            &.clear-value {
                color: $color-letter-selector;
                width: 100%;
                padding-right: 8px;
                font-size: 1.1em;
                padding-left: 38px;
                padding-right: 8px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                overflow-wrap: anywhere;
            }
        }

        &.portrait {
            .clear-value {
                font-size: 0.8em;
            }
        }
        .items-list {
            margin-top: 2px;
            max-height: 280px;
            overflow-y: auto;
            padding-right: 8px;
            width: 100%;

            &.mobile-landscape {
                max-height: 205px;
                .selector-item {
                    font-size: 0.8em;
                    min-height: 1.2em;
                    color: $color-letter-selector;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    overflow-wrap: anywhere;
                }
            }
            .selector-color {
                padding: 5px;
                @include border-radius(100%);
                margin-left: 8px;

                &.no-color {
                    border: 1px solid $color-selector2;
                    position: relative;
                }
            }

            .selector-item {
                font-size: 1.1em;
                min-height: 1.2em;
                color: $color-letter-selector;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.categories {
    width: 100%;
    position: static;
    top: 0;
    background-color: $default-bg-color;
    display: flex;

    &.alert-active {
        top: 70px;
    }

    .categories-title {
        @include font-size(sm);
        font-family: $conden;
        width: 100%;
        text-align: left;
        display: inline-block;
        padding-left: 6px;
        color: $neutro-s70;
    }

    .category-options {
        display: inline-table;
        // width: calc(100% - 100px);
        width: calc(100%);
        height: auto;
        overflow: all;

        .category {
            @include interaction();
            display: inline-block;
            background-color: #fff;
            padding: 7px 15px;
            border-radius: 3px;
            width: max-content;
            margin: 5px;
            height: fit-content;
            font-family: $text;
            text-transform: uppercase !important;
            white-space: nowrap;

            @include bgHover(#fff);

            &.selected {
                background-color: $main;
                color: #fff;

                @include bgHover($main);
            }

            &.hide {
                display: none;
            }

            &.display {
                @include background($image: img('down_arrow_neutro_s70.svg'), $size: 18px, $position: left 5px center);
                background-color: darken($default-bg-color, 5%);
                color: $neutro-s70;
                text-transform: unset !important;
                padding-left: 24px;

                @include bgHover(darken($default-bg-color, 5%));

                &.less {
                    @include background($image: img('up_arrow_neutro_s70.svg'), $size: 18px, $position: left 5px center);
                }
            }

            &.transparent {
                background-image: none;
                background-color: transparent;
                color: transparent;
                content: '';
            }
        }
    }

    .hamburger-menu {
        display: none;
        pointer-events: none;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        position: absolute;
        top: 17px;
        left: 13px;
        width: 45px; /* Ajusta según el tamaño de tu imagen */
        height: 45px; /* Ajusta según el tamaño de tu imagen */
        @include background($image: img('collection_list_portrait.svg'), $color: #fff, $size: 25px !important);
        @include border-radius(4px);

        &.active {
            @include background($image: img('collection_list_portrait_active.svg'), $color: $main-dark, $size: 25px !important);
            background-size: cover; /* Ajusta según el tamaño y la proporción de tu imagen */
            background-position: center;
            color: transparent; /* Oculta el símbolo */
        }

        /* Imagen por defecto cuando no está activo */
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 6h18v2H3zm0 4h18v2H3zm0 4h18v2H3z"/></svg>'); /* Símbolo hamburguesa en formato SVG */
        background-size: cover;
        background-position: center;
        color: transparent; /* Oculta el símbolo */
    }

    .category-options {
        display: inline-table;
        width: calc(100%);
        height: auto;
        overflow: all;

        .category {
            display: inline-block;
            background-color: #fff;
            padding: 7px 15px;
            border-radius: 3px;
            margin: 5px;
            text-transform: uppercase !important;
            white-space: nowrap;

            .category-text {
                font-family: $text;
            }

            &.selected {
                background-color: $main;
                .category-text {
                    color: #fff;
                }
            }
        }

        @media (max-width: 768px) {
            display: flex;
            flex-direction: column; /* Colocar en una sola columna */
            width: 98% !important;
            position: absolute;
            top: 70px; /* Asegura que el listado esté debajo del botón */
            left: 0;
            background-color: transparent; /* Fondo blanco para el menú desplegable */
            z-index: 1000; /* Asegura que el menú esté por encima de otros elementos */
        }

        &.mobile-hidden {
            display: none; /* Ocultar el menú por defecto */
        }
    }

    @media (max-width: 768px) {
        .hamburger-menu {
            display: block;
        }

        .category-options.mobile-hidden {
            display: block; /* Mostrar el menú cuando esté activo */
        }

        .corner-dot {
            position: absolute;
            width: 1.125rem; /* Tamaño del círculo */
            height: 1.125rem; /* Tamaño del círculo */
            background-color: #ff0000; /* Color del círculo */
            border-radius: 50%; /* Hace que el punto sea redondo */
            margin-right: 1.125rem;
            margin-top: 0.9rem !important;
            margin-left: 0.5rem !important;
        }

        .category {
            background: #fff;
            width: 98% !important;
            padding-left: 1.3rem !important;
            padding-top: 1.3rem !important;
            margin-left: 0.6rem !important;
            border-radius: 5px !important;
            min-height: 3.75rem !important;
            margin-bottom: 2px !important;
            &.selected {
                background-color: #fff !important;
                .category-text {
                    font-family: $text-bold !important;
                    color: $color-black !important;
                    font-size: 1.125rem !important;
                    line-height: inherit !important;
                    white-space: break-spaces !important;
                    word-break: break-word !important;
                    font: inherit !important;
                }
            }

            @media (max-width: 629px) {
                width: 97.2% !important;
            }
            @media (max-width: 522px) {
                width: 96.2% !important;
            }
            @media (max-width: 417px) {
                width: 96.2% !important;
            }
            @media (max-width: 305px) {
                width: 93.2% !important;
            }
        }

        .category-text {
            font-size: 1.125rem;
            font-weight: bold;
            position: relative;
            margin-left: 1.125rem;
            text-transform: capitalize;
        }

        .category-hide {
            display: none !important;
        }
    }
}

.category {
    position: relative;
    padding-left: 20px !important; /* Ajusta según sea necesario */
}

.corner-dot {
    position: absolute;
    top: 10px; /* Ajusta la posición vertical del círculo */
    left: 5px; /* Ajusta la posición horizontal del círculo */
    width: 10px; /* Tamaño del círculo */
    height: 10px; /* Tamaño del círculo */
    background-color: #ff0000; /* Color del círculo */
    border-radius: 50%; /* Hace que el punto sea redondo */
}

.category-selected {
    @include display-flex();
    @include flex-direction(row);
    width: auto;
    height: 45px;
    cursor: pointer;
    $size-bolita: rem(18px);
    margin-left: -0.3rem;
    margin-top: -9px;
    margin-bottom: -2.3rem;

    .return-button {
        @include background($image: img('left_dark.svg'), $color: #fff, $size: $size-bolita);
        @include border-radius(5px 0 0 5px);
        width: 45px;
        height: 45px;
        pointer-events: auto;
        z-index: 99;
    }

    .name-category {
        @include display-flex();
        @include align-items(center);
        @include border-radius(0px 5px 5px 0px);
        width: auto;
        height: 45px;
        align-self: center;
        padding-left: 10px;
        padding-right: 10px;

        .color {
            width: 18px;
            height: 18px;
            min-width: 18px;
            min-height: 18px;
            border-radius: 50%;
            filter: brightness(70%);
            margin-right: 10px;
        }
        .name {
            @include display-flex();
            @include align-items(center);
            @include text-ellipsis();
            font-family: $text-sbold;
            display: inline-block;
            width: 100%;
            color: #fff;
            height: auto;
            max-width: 45vw;
            // max-width: 120px;
        }
    }
}

.more-actions-container {
    height: auto;
    margin-left: 4px;
    margin-top: -2.3rem !important;
    padding: 8px;
}

.appcc-top-detail {
    width: 5% !important;
}

@media (max-width: 768px) {
    .appcc-top-detail {
        width: 100% !important;
        margin-left: -1%;
        margin-top: -16px;
    }
    .categories {
        margin-top: rem(15px);
        padding-left: rem(6px);
    }
}

.timers {
    &.portrait {
        .top-container {
            height: 60px;
        }
        .container {
            height: calc(100% - 60px);
        }
    }
}
</style>
